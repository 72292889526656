import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import Image from 'components/shared/ImageGatsby'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.color.text.secondary};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['21']};
    color: ${(props) => props.theme.color.text.dark};
  }

  & p {
    font-size: ${(props) => props.theme.font.size['14']};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  & > a {
    padding: 20px 15px;
    background: ${(props) => props.theme.color.text.light};
    color: ${(props) => props.theme.color.text.dark};
    border: 1px solid ${(props) => props.theme.color.face.dark};

    @media (max-width: 767px) {
      padding: 15px 10px;
    }

    &:hover {
      color: ${(props) => props.theme.color.text.main};
      background-color: ${(props) => props.theme.color.face.secondary};
      border: 1px solid ${(props) => props.theme.color.face.secondary};
    }
  }
  @media (max-width: 767px) {
    min-width: 180px;
  }
`

const StyledImage = styled(Image)`
  min-height: 300px;
`

interface TextWithImageDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
  location?: any
}

const TextWithImageDefault: React.FC<TextWithImageDefaultProps> = ({
  fields,
}) => (
  <section>
    <div className="container py-4 py-lg-5 mt-5 mt-lg-3 mb-lg-3">
      {/* no fear no-gutters is here */}
      <div className="row no-gutters">
        <ContentWrapper className="col-md-7 col-sm-5">
          <Content
            content={fields.description}
            className="pt-lg-5 pb-lg-5 pb-3 px-lg-5 px-3 pt-3"
          />
          {fields?.link && (
            <div className="p-lg-5 p-3">
              <StyledButtonDefault
                to={fields?.link?.url || ''}
                className="text-center"
              >
                {fields?.link?.title}
              </StyledButtonDefault>
            </div>
          )}
        </ContentWrapper>
        {fields?.image && (
          <div className="col-md-5 col-sm-7 flex-grow-1 d-flex mt-0">
            <StyledImage image={fields?.image} alt="Lofthus" loading="lazy" />
          </div>
        )}
      </div>
    </div>
  </section>
)

export default TextWithImageDefault
