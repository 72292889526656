import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'

const Wrapper = styled.section`
  @media (min-width: 992px) {
    margin-bottom: 14rem;
  }
`

const StyledImage = styled(Image)`
  max-width: 920px;

  @media (min-width: 992px) {
    height: 550px;
  }
  @media (max-width: 767px) {
    min-height: 200px;
  }
`

const CustomCol = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    z-index: 2;
    background: ${(props) => props.theme.color.face.light};
    right: 0;
    bottom: -100px;
    min-width: 500px;
    min-height: 200px;
    border: 1px solid ${(props) => props.theme.color.face.secondary};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['21']};
  }

  & span {
    min-width: 175px;
    & > a {
      margin-bottom: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
`

interface TextWithImageTopLeftProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const TextWithImageTopLeft: React.FC<TextWithImageTopLeftProps> = ({
  fields,
}) => (
  <Wrapper className="container position-relative pb-5">
    <div className="row">
      <div className="col-xl-9 col-lg-6 col-12">
        <StyledImage image={fields?.image} alt="Lofthus" loading="lazy" />
      </div>
      <CustomCol className="col-xl-3 col-lg-6 col-12 w-50">
        <Content content={fields?.description} className="px-0 px-lg-4 py-4" />
      </CustomCol>
    </div>
  </Wrapper>
)

export default TextWithImageTopLeft
