import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'
import styled from 'styled-components'

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.mainLight};

  & h2 {
    font-size: ${(props) => props.theme.font.size['21']};
    font-weight: ${(props) => props.theme.font.weight.s};
    color: ${(props) => props.theme.color.text.dark};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size['21']};
  }

  & a:not(.text-link) {
    background-color: ${(props) => props.theme.color.face.light};
    color: ${(props) => props.theme.color.text.main};
    border: 1px solid ${(props) => props.theme.color.face.secondary};
    padding: 20px 15px;
    width: 200px;
    text-align: center;
    height: 100%;
    display: block;
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.color.face.secondary};
    }
  }

  & a.text-link {
    color: #EB6209;
    text-decoration: underline;
  }
`

const StyledImage = styled(Image)`
  max-width: 350px;
  @media (max-width: 991px) {
    max-width: 250px;
  }
  @media (max-width: 575px) {
    max-width: 110%;
    min-width: 110% !important;
    margin-left: -20px;
    margin-top: -415px;
    opacity: 0.1;
  }
`

interface TextWithImageLeftProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
  location?: any
}

const TextWithImageLeft: React.FC<TextWithImageLeftProps> = ({ fields }) => (
  <Wrapper className="py-lg-5 py-1" id="package">
    <div className="container py-5">
      <h2 className="mb-4 text-left">{fields?.title}</h2>

      <div className="row">
        {fields?.image && (
          <div className="col-sm-4 order-2 order-sm-1 mt-4 mt-lg-0 d-flex justify-content-center">
            <div className="w-100">
              <StyledImage
                image={fields?.image}
                alt="Lofthus"
                className="w-100"
                loading="lazy"
              />
            </div>
          </div>
        )}

        <div className="col-sm-8 order-1 order-sm-2">
          <Content
            content={fields.description}
            className={`${fields.image === null ? 'p-0' : 'px-lg-5 px-0 pt-lg-0 pt-4'
              }`}
          />
        </div>
      </div>
    </div>
  </Wrapper>
)

export default TextWithImageLeft
